import * as fal from "@fal-ai/serverless-client";

fal.config({
    credentials: process.env.REACT_APP_FAL_API_KEY
});

export const generateDalleImage = async (prompt) => {
    try {
        const result = await fal.subscribe("fal-ai/flux/dev", {
            input: {
                prompt: prompt,
            },
        });

        return result.images[0].url;
    } catch (error) {
        console.error(error);
        return null;
    }
};