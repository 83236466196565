import React, { useState, useEffect } from "react";
import emailjs from "emailjs-com";
import { ToastContainer, toast } from "react-toastify";

const EmailForm = () => {
  const [email, setEmail] = useState("");

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://assets.calendly.com/assets/external/widget.js";
    script.async = true;
    document.body.appendChild(script);

    const link = document.createElement("link");
    link.href = "https://assets.calendly.com/assets/external/widget.css";
    link.rel = "stylesheet";
    document.head.appendChild(link);
  }, []);

  const handleCalendly = () => {
    window.Calendly.initPopupWidget({
      url: "https://calendly.com/paterson-projects",
    });
    return false;
  };

  const sendEmail = async (email) => {
    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        { email: email },
        process.env.REACT_APP_EMAILJS_USER_ID
      )
      .then(
        (result) => {
          toast.success("Email sent successfully!");
          setEmail("");
        },
        (error) => {
          toast.error("Failed to send email. Please try again.");
        }
      );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    sendEmail(email);
  };

  return (
    <div className="flex flex-col items-center w-full">
      <div className="w-full">
        <form
          onSubmit={handleSubmit}
          className="grid gap-2 grid-cols-1 sm:w-full sm:flex sm:items-center sm:flex-wrap mt-6 sm:mt-12 sm:mx-auto sm:max-w-lg w-full"
        >
          <label className="sr-only">Email address</label>
          <input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            id="cta-email"
            type="email"
            className="block w-full px-5 py-3 text-base text-gray-900 placeholder-gray-500 border-2 rounded-md shadow-sm border-green-600 focus:border-green-700 focus-visible:border-green-700 focus:ring-green-700 focus-visible:ring-green-700 flex-1"
            required
            placeholder="Enter your email..."
          />

          <div>
            <button
              className="bg-green-600 hover:bg-green-700 text-white font-bold px-5 py-3 rounded w-full"
              type="submit"
            >
              Submit
            </button>
          </div>
          <ToastContainer />
        </form>
      </div>

      <p className="my-4">Or</p>

      <div className="w-full sm:mx-auto sm:max-w-lg">
        <button
          onClick={(e) => {
            e.preventDefault();
            handleCalendly();
          }}
          className="block w-full text-center bg-green-600 hover:bg-green-700 text-white font-bold px-5 py-3 rounded mt-4"
        >
          Schedule time with me
        </button>
      </div>
    </div>
  );
};

export default EmailForm;
