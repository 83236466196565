import React from "react";
import "react-toastify/dist/ReactToastify.css";
import EmailForm from "./EmailForm";

const ContactUs = () => {
  return (
    <section
      className="relative pb-12 sm:py-20 md:py-24 lg:py-32"
      site-logo-url="https://storage.googleapis.com/mixo-sites/images/file-f4582464-c5de-4946-8d35-c3b00b3d89f1.png"
    >
      <div aria-hidden="true" className="block hidden sm:block" data-v-77b1cd82>
        <div className="absolute inset-y-0 left-0 w-1/2 bg-gray-50 rounded-r-3xl mb-12" data-v-77b1cd82></div>
        <svg className="absolute -ml-3 top-8 left-1/2" width="404" height="392" fill="none" viewBox="0 0 404 392" data-v-77b1cd82>
          <defs data-v-77b1cd82>
            <pattern
              id="8228f071-bcee-4ec8-905a-2a059a2cc4fb"
              x="0"
              y="0"
              width="20"
              height="20"
              patternUnits="userSpaceOnUse"
              data-v-77b1cd82
            >
              <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" data-v-77b1cd82></rect>
            </pattern>
          </defs>
          <rect width="404" height="392" fill="url(#8228f071-bcee-4ec8-905a-2a059a2cc4fb)" data-v-77b1cd82></rect>
        </svg>
      </div>
      <div className="max-w-md px-4 mx-auto sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8" data-v-77b1cd82>
        <div
          className="relative px-6 py-10 overflow-hidden bg-white border-green-600 border-2 shadow-xl rounded-2xl sm:px-12 sm:py-20"
          data-v-77b1cd82
        >
          <div aria-hidden="true" className="absolute inset-0 -mt-72 sm:-mt-32 md:mt-0" data-v-77b1cd82>
            <svg
              className="absolute inset-0 w-full h-full"
              preserveAspectRatio="xMidYMid slice"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 1463 360"
              data-v-77b1cd82
            >
              <path
                className="text-primary/5"
                fill="#17974420"
                d="M-82.673 72l1761.849 472.086-134.327 501.315-1761.85-472.086z"
                data-v-77b1cd82
              ></path>
              <path
                className="text-primary/10"
                fill="#7abb4120"
                d="M-217.088 544.086L1544.761 72l134.327 501.316-1761.849 472.086z"
                data-v-77b1cd82
              ></path>
            </svg>
          </div>
          <div className="relative" data-v-77b1cd82>
            <div className="mb-10 sm:text-center" data-v-77b1cd82>
              <h2
                className="text-2xl font-black tracking-tight text-gray-900 sm:text-3xl md:text-3xl md:leading-tight md:max-w-3xl md:mx-auto"
                data-v-77b1cd82
              >
                Look, we know navigating AI might be daunting, but we've got it down to an art. Lets start with a chat...
                <span className="text-primary" data-v-77b1cd82>
                  .
                </span>
              </h2>
            </div>
            <div className="mt-6 sm:mt-12 sm:mx-auto sm:max-w-lg flex flex-col items-center" data-v-77b1cd82></div>
            <ile-root id="ile-3" className="mt-6 sm:mt-12 sm:mx-auto sm:max-w-lg">
              <EmailForm />
            </ile-root>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
