import React, { useState, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import EmailForm from "./EmailForm";
import { generateDalleImage } from "./GenerateImage";

const Hero = ({ company, tag, subTag, imagePrompt, }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [image, setImage] = useState(null);

  useEffect(() => {
    const fetchImage = async () => {
      const imageUrl = await generateDalleImage(imagePrompt);
      if (!imageUrl) {
        return;
      }
      setImage(imageUrl);
      setIsLoading(false);
    };

    fetchImage();
  }, [imagePrompt]);

  return (
    <section className="pt-8 overflow-hidden sm:pt-12 lg:relative lg:py-48">
      <div className="max-w-md px-4 mx-auto sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl lg:grid lg:grid-cols-2 lg:gap-24">
        <div className="relative z-[1]">
          <div className="space-x-3 flex items-center">
            <img
              className="h-16 w-auto"
              src="https://storage.googleapis.com/mixo-sites/images/file-f4582464-c5de-4946-8d35-c3b00b3d89f1.png"
              alt="PatersonProjects logo"
            />
            <p className="text-4xl font-sans font-bold text-gray-900">{company}</p>
          </div>
          <div className="mt-14">
            <div className="mt-6 sm:max-w-xl">
              <h1 className="text-align: left; text-4xl font-black tracking-tight text-gray-900 sm:text-6xl md:text-7xl">
                {tag}
                <span className="text-primary">.</span>
              </h1>
              <h2 className="mt-6 text-lg text-gray-500 sm:text-xl">{subTag}</h2>
            </div>
            <div className="mt-10 space-y-4">
              <ile-root id="ile-1" className="mt-4 sm:max-w-lg">
                <EmailForm />
              </ile-root>
            </div>
          </div>
        </div>
      </div>
      <div className="sm:pl-6">
        <div className="py-12 sm:relative sm:mt-12 sm:py-16 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
          <div className="hidden sm:block">
            <div className="absolute inset-y-0 w-screen left-1/2 bg-gray-50 rounded-l-3xl lg:left-80 lg:right-0 lg:w-full"></div>
            <svg className="absolute -mr-3 top-8 right-1/2 lg:m-0 lg:left-0" width="404" height="392" fill="none" viewBox="0 0 404 392">
              <defs>
                <pattern id="837c3e70-6c3a-44e6-8854-cc48c737b659" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                  <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor"></rect>
                </pattern>
              </defs>
              <rect width="404" height="392" fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)"></rect>
            </svg>
          </div>
          <div className="relative pl-4 ml-auto sm:max-w-3xl sm:px-0 lg:h-full lg:max-w-none lg:flex lg:items-center xl:pl-12">
            {isLoading ? (
              <div className="flex items-center justify-center w-full h-full">
                <div className="loading"></div>
              </div>
            ) : (
              <img
                className="w-full rounded-l-3xl lg:w-auto 2xl:h-full 2xl:max-w-none 2xl:rounded-3xl"
                src={image}
                alt="PatersonProjects"
              />
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default React.memo(Hero);