import React, { useState, useEffect } from "react";
import { generateDalleImage } from "./GenerateImage";

const Feature = ({
  align,
  title,
  paragraph1,
  paragraph2,
  imagePrompt,
}) => {
  const [isLeft] = useState(align === "left");
  const [isLoading, setIsLoading] = useState(true);
  const [image, setImage] = useState(null);

  useEffect(() => {
    const fetchImage = async () => {
      const imageUrl = await generateDalleImage(imagePrompt);
      if (!imageUrl) {
        return;
      }
      setImage(imageUrl);
      setIsLoading(false);
    };

    fetchImage();
  }, [imagePrompt]);

  return (
    <div className="lg:mx-auto lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-6 xl:gap-12 2xl:gap">
      <div
        className={`${isLeft
          ? "lg:mx-auto lg:max-w-3xl xl:pr-12 2xl:pr-20 2xl:justify-self-start"
          : "lg:mx-auto lg:max-w-3xl xl:pl-12 2xl:pl-20 2xl:justify-self-end"
          } max-w-xl px-4 mx-auto space-y-6 sm:px-6 lg:py-32 2xl:mx-0`}
      >
        <div>
          <h2 className="text-4xl font-extrabold tracking-tight text-gray-900">
            {title}
            <span className="text-primary">.</span>
          </h2>
          <p className="mt-4 text-lg leading-relaxed text-gray-500 sm:text-xl">
            {paragraph1}
          </p>
          <br />
          <p className="mt-4 text-lg leading-relaxed text-gray-500 sm:text-xl">
            {paragraph2}
          </p>
        </div>
      </div>
      <div
        className={`${isLeft
          ? "lg:col-start-1 mt-12 sm:mt-16 lg:mt-0"
          : "mt-12 sm:mt-16 lg:mt-0"
          }`}
      >
        <div
          className={`${isLeft
            ? "pr-4 -sm:ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full lg:flex lg:items-center"
            : "pl-4 -sm:mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full lg:flex lg:items-center"
            }`}
        >
          {isLoading ? (
            <div className="flex items-center justify-center w-full h-full">
              <div className="loading"></div>
            </div>
          ) : (
            <img
              src={image}
              className={`${isLeft
                ? "lg:right-0 rounded-r-2xl w-full 2xl:max-h-[44rem]"
                : "lg:left-0 rounded-l-2xl w-full 2xl:max-h-[44rem]"
                }`}
              alt="Feature"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default React.memo(Feature);