import React from "react";
import Hero from "./components/Hero";
import Feature from "./components/Feature";
import ContactUs from "./components/ContactUs";
import content from "./content/playful.json";

function App() {

  return (
    <div className="App">
      <Hero
        company={"Paterson Projects"}
        tag={content.playful.hero.tag}
        subTag={content.playful.hero.subTag}
        imagePrompt={content.playful.hero.prompt}
      />
      <section
        className="relative pb-16 overflow-hidden bg-white space-y-12"
        site-logo-url="https://storage.googleapis.com/mixo-sites/images/file-f4582464-c5de-4946-8d35-c3b00b3d89f1.png"
      >
        {
          content.playful.services.map((service, index) => (
            <Feature
              key={index}
              align={service?.align}
              title={service.title}
              paragraph1={service.paragraph1}
              paragraph2={service.paragraph2}
              imagePrompt={service.prompt}
            />
          ))}
      </section>
      <ContactUs />
    </div>
  );
}

export default App;
